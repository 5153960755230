import React, { useEffect, useRef } from 'react';
import { LogoHorizontal } from './LogoHorizontal';
import { formatName } from './utils';
import { NoProfilePicture } from './NoProfilePicture';

export const Menu = ({ sessionInfo, unitDetails, activeTab, handleTabSelection, isOpen, onClose }) => {
  const drawerRef = useRef(null);

  // Close drawer if clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      {isOpen && <div className="overlay" onClick={onClose} />}
      <div className={`side-drawer ${isOpen ? 'open' : ''}`} ref={drawerRef}>
        <button
          className="close-btn"
          onClick={onClose}
          style={{ backgroundColor: "transparent", border: "none", outline: "none" }}
        >
          <i className="bx bx-x"></i>
        </button>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '1 1 auto',
        }}>
          <div style={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <div style={{ 
              marginBottom: '8px',
              marginTop: '20px',
              marginLeft: '-50px',
            }}>
              <LogoHorizontal scale="1.5" />
            </div>
            <hr
              style={{ 
                border: "none", 
                borderTop: "1px solid #f0f0f0",
                width: "220px",
                marginBottom: "20px",
              }}
            />
            <div
              style={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '10px',
              }}
            >
              {sessionInfo.accessLevel === "BISHOPRIC" &&
                <button
                  className={`dropdown-item ${activeTab === "home" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("home");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "home" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "home" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "home" ? "#2684FC" : "black" }}
                >
                  <i className="bx bx-home" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Inicio" : "Home"}
                </button>
              }
              {sessionInfo.accessLevel === "PRESIDENCY" &&
                <button
                  className={`dropdown-item ${activeTab === "myOrganization" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("myOrganization");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "myOrganization" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "myOrganization" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "myOrganization" ? "#2684FC" : "black" }}
                >
                  <i className="bx bx-home" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Mi organización" : "My organization"}
                </button>
              }
              <button
                className={`dropdown-item ${activeTab === "callingsByOrganization" ? "selected" : ""}`}
                onClick={() => {
                  onClose();
                  setTimeout(() => {
                    handleTabSelection("callingsByOrganization");
                  }, 100);
                }}
                style={{ width: "100%", backgroundColor: activeTab === "callingsByOrganization" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "callingsByOrganization" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "callingsByOrganization" ? "#2684FC" : "black" }}
              >
                <i className="bx bx-sitemap" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                {unitDetails.language === "es" ? "Llamamientos por organización" : "Callings by organization"}
              </button>
              {sessionInfo.accessLevel === "BISHOPRIC" &&
                <>
                  <button
                    className={`dropdown-item ${activeTab === "membersWithoutCallings" ? "selected" : ""}`}
                    onClick={() => {
                      onClose();
                      setTimeout(() => {
                        handleTabSelection("membersWithoutCallings");
                      }, 100);
                    }}
                    style={{ width: "100%", backgroundColor: activeTab === "membersWithoutCallings" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "membersWithoutCallings" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "membersWithoutCallings" ? "#2684FC" : "black" }}
                  >
                    <i className="bx bx-group" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Miembros sin llamamientos" : "Members without callings"}
                  </button>
                  <button
                    className={`dropdown-item ${activeTab === "prospectiveElders" ? "selected" : ""}`}
                    onClick={() => {
                      onClose();
                      setTimeout(() => {
                        handleTabSelection("prospectiveElders");
                      }, 100);
                    }}
                    style={{ width: "100%", backgroundColor: activeTab === "prospectiveElders" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "prospectiveElders" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "prospectiveElders" ? "#2684FC" : "black" }}
                  >
                    <i className="bx bx-badge-check" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                    {unitDetails.language === "es" ? "Élderes en perspectiva" : "Prospective Elders"}
                  </button>
                </>
              }
              {["BISHOPRIC", "PRESIDENCY", "COMMUNICATION"].includes(sessionInfo.accessLevel) && unitDetails.twilio_phone_number &&
                <button
                  className={`dropdown-item ${activeTab === "communication" ? "selected" : ""}`}
                  onClick={() => {
                    onClose();
                    setTimeout(() => {
                      handleTabSelection("communication");
                    }, 100);
                  }}
                  style={{ width: "100%", backgroundColor: activeTab === "communication" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "communication" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "communication" ? "#2684FC" : "black" }}
                >
                  <i className="bx bx-message" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Comunicación" : "Communication"}
                </button>
              }
              <button
                className={`dropdown-item ${activeTab === "ministering" ? "selected" : ""}`}
                onClick={() => {
                  onClose();
                  setTimeout(() => {
                    handleTabSelection("ministering");
                  }, 100);
                }}
                style={{ width: "100%", backgroundColor: activeTab === "ministering" ? "rgba(38, 132, 252, 0.1)" : "transparent", border: activeTab === "ministering" ? "1px solid #2684FC" : "none", outline: "none", fontSize: "16px", color: activeTab === "ministering" ? "#2684FC" : "black" }}
              >
                <i className="bx bx-donate-heart" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                {unitDetails.language === "es" ? "Ministerio" : "Ministering"}
              </button>
              {/* {sessionInfo.accessLevel === "BISHOPRIC" &&
                <DropdownMenuRadioItem
                  value="covenantPath"
                  className={`dropdown-item ${activeTab === "covenantPath" ? "selected" : ""}`}
                  style={{ backgroundColor: "#f5f5f5", color: "#a0a0a0", cursor: "default" }}
                  disabled
                >
                  <i className="bx bxs-direction-right" style={{ marginRight: '5px', fontSize: "18px", position: 'relative', top: '2px' }}></i>
                  {unitDetails.language === "es" ? "Sendero de convenios" : "Covenant path"}
                  <span style={{ marginLeft: "22px", fontSize: "12px", backgroundColor: "#abf2b8", borderRadius: "4px", border: "2px", padding: "1px" }}>
                    {unitDetails.language === "es" ? "PRÓXIMAMENTE" : "COMING SOON"}
                  </span>
                </DropdownMenuRadioItem>
              } */}
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <hr style={{ 
                border: "none", 
                borderTop: "1px solid #f0f0f0",
                width: "220px",
            }}/>
            <div style={{ display: "flex", alignItems: "center", padding: "2px", fontSize: '16px', margin: "10px", gap: "10px" }}>
              <div style={{ marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {sessionInfo.member_photo || sessionInfo.household_photo ?
                  <img
                    src={sessionInfo.member_photo || sessionInfo.household_photo}
                    alt={sessionInfo.name}
                    style={{ width: "35px", height: "35px", borderRadius: "50%", border: "0.5px solid #b0b0b0", objectFit: "cover" }}
                  /> : <NoProfilePicture />
                }
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <span style={{ fontWeight: "bold" }}>{formatName(sessionInfo.name)}</span>
                {sessionInfo.position && <span style={{ fontStyle: "italic", fontSize: "14px" }}>{sessionInfo.position}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
