import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { components } from 'react-select';

export const AddCallingCardComplex = ({
  onAdd,
  organizations,
  unitDetails,
}) => {
  const [isAdding, setIsAdding] = useState(false);
  const [callingName, setCallingName] = useState("");
  const [parentOrganization, setParentOrganization] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [dropdownOrganizations, setDropdownOrganizations] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(callingName, organization.value);
    setIsAdding(false);
    setCallingName("");
    setParentOrganization(null);
    setOrganization(null);
  };

  if (!isAdding) {
    return (
      <div
        className="add-card" 
        style={{ cursor: "pointer" }}
        onClick={() => {
          setIsAdding(true);
          setDropdownOrganizations(organizations.filter(org => org.parent_id === null).map(org => ({ value: org.id, label: org.name })));
        }}>
          <div
            className="bx bx-plus-circle"
            style={{ fontSize: "24px" }}
          ></div>
      </div>
    );
  }

  const inputStyles = {
    fontSize: "16px",
    width: "94%",
    marginTop: "2px",
    border: "1px solid #b0b0b0",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingLeft: "10px",
    borderRadius: "4px",
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      textAlign: "left",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "16px",
      textAlign: "left",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #b0b0b0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      zIndex: 5000,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const CustomMenu = (props) => {
    const handleHeaderClick = () => {
      setParentOrganization(null);
      setDropdownOrganizations(organizations.filter(org => org.parent_id === null).map(org => ({ value: org.id, label: org.name })));
    };
  
    return (
      <components.Menu {...props}>
        {parentOrganization && (
          <div 
            style={{ padding: '8px', fontWeight: 'bold', cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
            onClick={handleHeaderClick}
          >
            <i className="bx bx-chevron-left" style={{ fontSize: "16px", marginRight: '8px' }}></i>
            {parentOrganization.label}
          </div>
        )}
        {props.children}
      </components.Menu>
    );
  };

  const CustomOption = (props) => {
    const showChevron = organizations.some(org => org.parent_id === props.data.value) && parentOrganization === null;
    
    return (
      <components.Option {...props}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>{props.data.label}</span>
          {showChevron && <i className="bx bx-chevron-right" style={{ fontSize: '16px' }}></i>}
        </div>
      </components.Option>
    );
  };

  return (
    <div className="add-card">
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div ref={selectRef}>
            <Select
              id="organization"
              value={organization}
              onChange={(selectedOption) => {
                const childOrganizations = organizations.filter(org => org.parent_id === selectedOption.value);
                if (parentOrganization === null && childOrganizations.length > 0) {
                  const selectedOrganization = organizations.find(org => org.id === selectedOption.value);
                  setParentOrganization(selectedOption);
                  const shouldPrependPresidency = selectedOrganization.allow_new_callings && selectedOrganization.has_presidency;
                  const updatedDropdownOrganizations = [
                    ...(shouldPrependPresidency ? [{ value: selectedOption.value, label: "Presidency" }] : []),
                    ...(!shouldPrependPresidency ? [{ value: selectedOption.value, label: selectedOption.label }] : []),
                    ...childOrganizations.map(org => ({ value: org.id, label: org.name })),
                  ];
                  setDropdownOrganizations(updatedDropdownOrganizations);
                } else {
                  setOrganization(selectedOption);
                  setMenuIsOpen(false);
                }
              }}
              options={dropdownOrganizations}
              placeholder={unitDetails.language === "es" ? "Organización" : "Organization"}
              styles={selectStyles}
              components={{ Menu: CustomMenu, Option: CustomOption }}
              menuIsOpen={menuIsOpen}
              onMenuOpen={() => setMenuIsOpen(true)}
            />
          </div>
          {organization &&
            <div>
              <input
                style={inputStyles}
                type="text"
                value={callingName}
                onChange={(e) => setCallingName(e.target.value)}
                placeholder={unitDetails.language === "es" ? "Llamamiento" : "Calling"}
                required
              />
            </div>
          }
        </div>
        {organization &&
          <div>
            <button
              className="cta"
              disabled={callingName.length === 0}
              style={{
                cursor: callingName.length === 0 ? "default" : "pointer",
              }}
            >
              {unitDetails.language === "es" ? "Guardar" : "Save"}
            </button>
          </div>
        }
      </form>
    </div>
  );
};
