import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { MemberDetails } from "./MemberDetails";
import { NoProfilePicture } from "./NoProfilePicture";

export const MemberWithoutCalling = ({ member, updateIsPrioritized, updateIneligibleForCalling, zoomedOut, sessionInfo, unitDetails, setMembersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [isPrioritized, setIsPrioritized] = useState(member.is_prioritized);
  const [ineligibleForCalling, setIneligibleForCalling] = useState(member.ineligible_for_calling);
  const isRecommended = member.is_recommended;
  const [showDetails, setShowDetails] = useState(false);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  const name = member.member_name.split(",").map((part) => part.trim()).reverse().join(" ");
  const monthsSinceMovedIn = member.months_since_moved_in;

  let borderColor = "#b0b0b0";
  if (monthsSinceMovedIn >= 6) {
    borderColor = "#a33b7d";
  } else if (monthsSinceMovedIn >= 3) {
    borderColor = "#d988bb";
  }

  const handleUpdateIsPrioritized = () => {
    setIsPrioritized(!isPrioritized);
    updateIsPrioritized(member, !isPrioritized);
  };

  const handleUpdateIneligibleForCalling = () => {
    setIneligibleForCalling(!ineligibleForCalling);
    updateIneligibleForCalling(member, !ineligibleForCalling);

    // Whether moving someone into or out of the "Ineligible for calling" section, we want to reset is_prioritized
    setIsPrioritized(false);
    updateIsPrioritized(member, false);
  };

  if (zoomedOut) {
    return (
      <>
        <div
          className="member-card"
          style={{
            border: `3px solid ${borderColor}`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              height: "125px",
              width: "125px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo" /> : <NoProfilePicture />}
          </div>
          <div className="name" onClick={() => setShowDetails(true)}>
            {name}
          </div>
        </div>
        {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} setMembersList={setMembersList} />}
      </>
    );
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <DropdownMenu>
          <DropdownMenuTrigger style={{cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000}} asChild>
            <i className="bx bx-dots-vertical-rounded" style={{ fontSize: "24px" }}></i>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className="dropdown-content"
            align="end"
            side="bottom"      
            style={{zIndex: 1000}}
          >
            <DropdownMenuRadioGroup>
              {!isPrioritized && !ineligibleForCalling && !isRecommended && (
                <DropdownMenuRadioItem
                  className='dropdown-item'
                  onClick={handleUpdateIsPrioritized}
                  style={{ fontSize: "18px" }}
                >
                  <i className="bx bx-star" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Priorizar para el llamamiento" : "Prioritize for calling"}
                </DropdownMenuRadioItem>
              )}
              {isPrioritized && !ineligibleForCalling && (
                <DropdownMenuRadioItem
                  className='dropdown-item'
                  onClick={handleUpdateIsPrioritized}
                  style={{ fontSize: "18px" }}
                >
                  <i className="bx bx-undo" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Eliminar estado de prioridad" : "Clear priority status"}
                </DropdownMenuRadioItem>
              )}
              {!ineligibleForCalling && (
                <DropdownMenuRadioItem
                  className='dropdown-item'
                  onClick={handleUpdateIneligibleForCalling}
                  style={{ fontSize: "18px" }}
                >
                  <i className="bx bx-user-x" style={{ marginRight: '5px', fontSize: "18px" }}></i>
                  {unitDetails.language === "es" ? "No elegible para el llamamiento" : "Ineligible for calling"}
                </DropdownMenuRadioItem>
              )}
              {ineligibleForCalling && (
                <DropdownMenuRadioItem
                  className='dropdown-item'
                  onClick={handleUpdateIneligibleForCalling}
                  style={{ fontSize: "18px" }}
                >
                  <i className="bx bx-undo" style={{ marginRight: '5px', fontSize: "16px" }}></i>
                  {unitDetails.language === "es" ? "Eliminar estado de inelegibilidad" : "Clear ineligible status"}
                </DropdownMenuRadioItem>
              )}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                right: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo"/> : <NoProfilePicture />}
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                left: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            marginTop: "36px",
            cursor: "pointer",
          }}
          onClick={() => setShowDetails(true)}
        >
          <strong>{name}</strong>
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} setMembersList={setMembersList} />}
    </>
  );
};