import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { formatPhoneNumber } from "./utils";

export const Communication = ({ unitDetails, membersList }) => {
  const [message, setMessage] = useState("");
  const [recipientGroup, setRecipientGroup] = useState(null);
  const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState([]);
  const [attachedFile, setAttachedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiButtonRef = useRef(null);
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && 
          !emojiPickerRef.current.contains(event.target) &&
          !emojiButtonRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "16px",
      width: "100%",
      textAlign: "left",
      color: "black",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "16px",
      textAlign: "left",
      color: "black",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "16px",
    }),
    menu: (provided) => ({
      ...provided,
      border: "1px solid #b0b0b0",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      margin: "0",
      zIndex: 5000,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: "0",
      paddingBottom: "0",
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  async function handleSend() {
    try {
      setIsSending(true);
      const formData = new FormData();
      formData.append("message", message);
      formData.append("recipientGroup", recipientGroup.value);
      formData.append("phoneNumbers", JSON.stringify(filteredPhoneNumbers));
      if (attachedFile) {
        formData.append("file", attachedFile);
      }

      const res = await fetch("/api/send_mass_text", {
        method: "POST",
        body: formData,
      });
      await res.json();
      setMessage("");
      setRecipientGroup(null);
      setFilteredPhoneNumbers([]);
      setAttachedFile(null);
      setPreviewUrl(null);
      setShowConfirm(false);
      setIsSending(false);

      // Show success toast
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Error sending SMS", error);
      setShowConfirm(false);
    }
  }

  function handleFileChange(event) {
    const file = event.target.files[0];
    const maxSize = 5 * 1024 * 1024;

    if (file) {
      if (file.size > maxSize) {
        event.target.value = '';
        setAttachedFile(null);
        setPreviewUrl(null);
        return;
      }
   
      setAttachedFile(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  }

  function handleRemoveFile() {
    setAttachedFile(null);
    setPreviewUrl(null);
    const fileInput = document.getElementById('fileInput');
    if (fileInput) fileInput.value = '';
  }

  const handleEmojiSelect = (emoji) => {
    setMessage(prev => prev + emoji.native);
    setShowEmojiPicker(false);
  };

  const confirmPopover = showConfirm && (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}>
        <div style={{ 
          margin: '10px',
          fontSize: '16px',
          color: 'black'
        }}>
          {unitDetails.language === "es"
            ? `Este mensaje se enviará a ${filteredPhoneNumbers.length} miembros.`
            : `This message will be sent to ${filteredPhoneNumbers.length} members.`}
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          marginTop: "15px"
        }}>
          <button
            onClick={() => setShowConfirm(false)}
            disabled={isSending} 
            style={{
              padding: '4px 8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              background: 'white',
              fontSize: '14px',
              width: '100px',
              opacity: isSending ? '0.65' : '1',
            }}
          >
            {unitDetails.language === "es" ? "Cancelar" : "Cancel"}
          </button>
          <button
            onClick={handleSend}
            disabled={isSending} 
            style={{
              padding: '4px 8px',
              border: 'none',
              borderRadius: '4px',
              background: '#2684FC',
              color: 'white',
              fontSize: '14px',
              width: '100px',
              opacity: isSending ? '0.65' : '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {isSending ? <i className="bx bx-loader-alt loading-spinner" style={{ fontSize: "17px" }} /> : unitDetails.language === "es" ? "Confirmar" : "Confirm"}
          </button>
        </div>
      </div>
    </div>
  );

  const successToast = (
    <div
      style={{
        position: 'fixed',
        top: '1rem',
        right: '1rem',
        backgroundColor: '#22c55e',
        color: 'white',
        padding: '0.5rem 1rem',
        borderRadius: '0.5rem',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        transform: showSuccess ? 'translateY(0)' : 'translateY(-1rem)',
        opacity: showSuccess ? 1 : 0,
        transition: 'all 0.3s ease',
        pointerEvents: showSuccess ? 'auto' : 'none',
        zIndex: 5000,
      }}
    >
      <svg
        style={{
          width: '1.25rem',
          height: '1.25rem',
        }}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
      <span>
        {unitDetails.language === "es" ? "Mensaje enviado" : "Message sent"}
      </span>
    </div>
  );

  return (
    <>
      <div style={{
        width: '300px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div style={{ display: "flex", width: "100%", gap: "6px" }}>
          <input
            type="file"
            id="fileInput"
            onChange={handleFileChange}
            accept="image/*"
            style={{
              display: "none",
            }}
          />
          <label htmlFor="fileInput" style={{
            cursor: "pointer",
            fontSize: "24px",
            display: "inline-block",
            position: "relative",
            top: "5px",
            marginRight: "5px",
          }}>
            <i className="bx bx-image-add communication-icon" style={{ fontSize: "27px" }} title={unitDetails.language === "es" ? "Agregar imagen" : "Add image"}></i>
          </label>
          <i
            className="bx bx-smile communication-icon" 
            style={{ 
              fontSize: "26px", 
              cursor: "pointer",
              position: "relative",
              top: "5px",
              marginRight: "5px"
            }}
            ref={emojiButtonRef}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            title={unitDetails.language === "es" ? "Añadir emoji" : "Add emoji"}
          />
          {showEmojiPicker && (
            <div
              style={{
                position: 'absolute',
                top: '40px',
                left: '-27px',
                zIndex: 1000,
              }}
              ref={emojiPickerRef}
            >
              <Picker 
                data={data}
                onEmojiSelect={handleEmojiSelect}
                theme="dark"
                previewPosition="none"
                skinTonePosition="none"
              />
            </div>
          )}
          <Select
            isSearchable={false}
            value={recipientGroup}
            onChange={(option) => {
              setRecipientGroup(option);
              setFilteredPhoneNumbers(membersList
                .filter(member => {
                  const contactable = !member.do_not_contact && member.phone_number != null;
            
                  if (option.value === "relief_society") {
                    return contactable && member.gender === 'F';
                  }
            
                  if (option.value === "elders_quorum") {
                    return contactable && member.gender === 'M';
                  }
      
                  return contactable;
                }).map(member => formatPhoneNumber(member.phone_number)));
            }}
            options={[{value: "everyone", label: "Everyone"}, {value: "elders_quorum", label: "Elders Quorum"}, {value: "relief_society", label: "Relief Society"}]}
            placeholder={unitDetails.language === "es" ? "Destinatarios" : "Recipients"}
            styles={selectStyles}
          />
          <button
            onClick={() => setShowConfirm(true)}
            style={{ fontSize: "16px", border: "none", background: "none", padding: 0, margin: 0, outline: "none" }}
            disabled={recipientGroup == null || message.length === 0 || membersList.length === 0}
          >
            <i style={{ marginLeft: "5px", fontSize: "26px", position: 'relative', top: '1px' }} className="bx bx-send communication-icon" title={unitDetails.language === "es" ? "Enviar" : "Send"}></i>
          </button>
        </div>
        <div style={{ display: "flex", flexDirection: "column", borderRadius: "4px", border: "1px solid #ccc", marginTop: "10px", alignItems: "flex-start" }}>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            maxLength={300}
            placeholder={unitDetails.language === "es" ? attachedFile ? "Mensaje de texto" : "Agregar texto" : attachedFile ? "Add text" : "Text message"}
            rows="8"
            style={{
              width: "100%",
              fontFamily: "inherit",
              fontSize: "16px",
              resize: "none",
              border: "none",
              outline: "none",
              boxSizing: "border-box",
              marginTop: "8px",
              marginBottom: "8px",
              paddingLeft: "8px",
              paddingRight: "8px",
            }}
          />
          {previewUrl && (
            <div style={{ position: "relative", margin: "5px" }}>
              <img 
                src={previewUrl} 
                alt="Preview" 
                style={{
                  left: "8px",
                  width: "64px",
                  height: "64px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  objectFit: "cover",
                  margin: "5px",
                }} 
              />
              <i 
                className="bx bx-x"
                onClick={handleRemoveFile}
                style={{
                  position: "absolute",
                  top: "-2px",
                  right: "-2px",
                  color: "black",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  cursor: "pointer",
                  fontSize: "18px",
                  boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.1)"
                }}
              />
            </div>
          )}
        </div>
      </div>
      {confirmPopover}
      {successToast}
    </>
  )
}