import { ViewToggleIcon } from "./ViewToggleIcon";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling

export const MembersWithoutCallingsLegend = ({ unitDetails }) => {
  return (
    <div className="legend">
      <div className="legend-controls">
        <div></div>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "#34a853",
              backgroundColor: "#34a853",
              margin: "4px",
              fontSize: "24px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <strong>Active</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              color: "#ffc107",
              backgroundColor: "#ffc107",
              marginRight: "4px",
              fontSize: "24px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <strong>Less active</strong>
        </div> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#d988bb",
              backgroundColor: "#d988bb",
            }}
          />
          <strong>{unitDetails.language === "es" ? "3-6 meses en el barrio" : "3-6 months in ward"}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#a33b7d",
              backgroundColor: "#a33b7d",
            }}
          />
          <strong>{unitDetails.language === "es" ? "6+ meses en el barrio" : "6+ months in ward"}</strong>
        </div>
        <div></div>
      </div>
    </div>
  );
};