import { formatName } from "./utils";
import React, { useState, useEffect, useRef } from "react";
import "react-day-picker/dist/style.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional for styling
import './tooltip.css'; // Custom tooltip styling
import { NoProfilePicture } from "./NoProfilePicture";

export const MemberDetails = ({ member, setShowDetails, sessionInfo, unitDetails, setMembersList }) => {
  const initialLoad = useRef(true);
  const [memberDetails, setMemberDetails] = useState({});
  const [notes, setNotes] = useState([]);
  const [name, setName] = useState(null);
  const [wantsCalling, setWantsCalling] = useState(null);
  const [wantsMinisters, setWantsMinisters]  = useState(null);
  const [wantsToMinister, setWantsToMinister] = useState(null);
  const [isInactive, setIsInactive] = useState(null);
  const [doNotContact, setDoNotContact] = useState(null);
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [isPhoneNumberHovered, setIsPhoneNumberHovered] = useState(false);
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const [isAddressHovered, setIsAddressHovered] = useState(false);
  const [copiedField, setCopiedField] = useState(null);
  const modalRef = useRef(null);
  const [noteText, setNoteText] = useState('');
  const [activeTab, setActiveTab] = useState("contact");

  async function fetchMemberDetails() {
    try {
      const res = await fetch("/api/member_details", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: member.member_id || member.recommended_id }),
      });
      const data = await res.json();
      const { member_photo, household_photo } = member;
      setMemberDetails({ member_photo, household_photo, ...data });
      setName(formatName(data.name));
      setWantsCalling(data.wants_calling);
      setWantsMinisters(data.wants_ministers);
      setWantsToMinister(data.wants_to_minister);
      setIsInactive(data.is_inactive);
      setDoNotContact(data.do_not_contact);
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  async function fetchNotes() {
    try {
      const res = await fetch("/api/list_notes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: member.member_id || member.recommended_id }),
      });
      const data = await res.json();
      setNotes(data)
    } catch (error) {
      console.error("Error executing query", error);
    }
  }

  if (initialLoad.current) {
    const loadData = async () => {
      await fetchMemberDetails();
      await fetchNotes();
    };

    loadData();
    initialLoad.current = false;
  }

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = memberDetails.member_photo;
  let householdPhotoSrc = memberDetails.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  function copyToClipboard(text, fieldName) {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedField(fieldName);
      setTimeout(() => setCopiedField(null), 1000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setMembersList((prevMembers) =>
          prevMembers.map((item) => 
            item.value === memberDetails.id
              ? { ...item, wants_calling: wantsCalling, wants_ministers: wantsMinisters, wants_to_minister: wantsToMinister, is_inactive: isInactive, do_not_contact: doNotContact }
              : item
          )
        );
        setShowDetails(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowDetails, wantsCalling, wantsMinisters, wantsToMinister, isInactive, doNotContact]);

  async function createNote(note) {
    try {
      const res = await fetch("/api/create_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, note: note }),
      });
      const data = await res.json();
      setNotes((prevNotes) => [...prevNotes, data]);
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function deleteNote(noteId) {
    try {
      await fetch("/api/delete_note", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ noteId: noteId }),
      });
      setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateWantsCalling(newValue) {
    try {
      setWantsCalling(newValue);
      await fetch("/api/update_wants_calling", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, wantsCalling: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateWantsMinisters(newValue) {
    try {
      setWantsMinisters(newValue);
      await fetch("/api/update_wants_ministers", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, wantsMinisters: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateWantsToMinister(newValue) {
    try {
      setWantsToMinister(newValue);
      await fetch("/api/update_wants_to_minister", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, wantsToMinister: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateIsInactive(newValue) {
    try {
      setIsInactive(newValue);
      await fetch("/api/update_is_inactive", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, isInactive: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  async function updateDoNotContact(newValue) {
    try {
      setDoNotContact(newValue);
      await fetch("/api/update_do_not_contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ memberId: memberDetails.id, doNotContact: newValue }),
      });
    } catch (error) {
      console.error("Error executing query", error);
      return [];
    }
  }

  const handleSave = () => {
    createNote(noteText);
    setNoteText('');
  };

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };

  function formatEnglishBirthdate(date) {
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: '2-digit',
      year: 'numeric'
    });
  }

  function formatSpanishBirthdate(date) {
    return date.toLocaleDateString('es-ES', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  }

  const WantsCallingTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Quiere un llamamiento</strong> está marcado por defecto.<br><br>
      Desmarque esta casilla si la persona no quiere un llamamiento.<br><br>
      Si se desmarca, la persona no aparecerá en la lista de personas recomendadas para un llamamiento.
    </div>
  ` : `
    <div>
      <strong>Wants a calling</strong> defaults to checked.<br><br>
      Uncheck this box if the individual does not want a calling.<br><br>
      If unchecked, the individual will not show up in the list of people to recommend for a calling.
    </div>
  `;

  const WantsToMinisterTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Quiere ministrar</strong> está marcado por defecto.<br><br>
      Desmarque esta casilla si la persona no quiere una asignación de ministración.<br><br>
      Si se desmarca, la persona no aparecerá en la lista de personas para poner en una compañía de ministración.
    </div>
  ` : `
    <div>
      <strong>Wants to minister</strong> defaults to checked.<br><br>
      Uncheck this box if the individual does not want a ministering assignment.<br><br>
      If unchecked, the individual will not show up in the list of people to put in a ministering companionship.
    </div>
  `;

  const WantsMinistersTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Quiere ser ministrado</strong> está marcado por defecto.<br><br>
      Desmarque esta casilla si la persona no quiere ser ministrada.<br><br>
      Si se desmarca, la persona no aparecerá en la lista de personas para agregar como asignación a una compañía de ministración.
    </div>
  ` : `
    <div>
      <strong>Wants ministers</strong> defaults to checked.<br><br>
      Uncheck this box if the individual does not want to be ministered to.<br><br>
      If unchecked, the individual will not show up in the list of people to add as an assignment for a ministering companionship.
    </div>
  `;

  const IsInactiveTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>Inactivo</strong> está desmarcado por defecto.<br><br>
      Cuando se marca <strong>Inactivo</strong>, se asume que el miembro no quiere un llamamiento ni quiere ministrar, por lo que <strong>Quiere un llamamiento</strong> y <strong>Quiere ministrar</strong> estarán desmarcados y deshabilitados.<br><br>
      Tenga en cuenta que <strong>Quiere ministros</strong> no está deshabilitado ni su valor cambia cuando <strong>Inactivo</strong> está marcado.
    </div>
  ` : `
    <div>
      <strong>Inactive</strong> defaults to unchecked.<br><br>
      When <strong>Inactive</strong> is checked, it is assumed that the member does not want a calling or want to minister, so <strong>Wants a calling</strong> and <strong>Wants to minister</strong> will be unchecked and disabled.<br><br>
      Note that <strong>Wants ministers</strong> is not disabled nor does the value change when <strong>Inactive</strong> is checked.
    </div>
  `;

  const DoNotContactTooltip = unitDetails.language === "es" ? `
    <div>
      <strong>No contactar</strong> está desmarcado por defecto.<br><br>
      Cuando se marca <strong>No contactar</strong>, se asume que el miembro está inactivo y no quiere un llamamiento ni quiere ser parte de la ministración, por lo que todas las demás casillas de verificación estarán deshabilitadas y configuradas con los valores asumidos.
    </div>
  ` : `
    <div>
      <strong>Do not contact</strong> defaults to unchecked.<br><br>
      When <strong>Do not contact</strong> is checked, it is assumed that the member is inactive and does not want a calling or want to be part of ministering, so all other checkboxes will be disabled and set to the assumed values.
    </div>
  `;
  
  return (
    <>
      <div className="modal-overlay"></div>
      <div className="modal" ref={modalRef}>
        <div
          className="member-card"
          style={{
            border: `5px solid #b0b0b0`,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{ cursor: "pointer", position: "absolute", top: "0px", right: "-2px", zIndex: 20000 }}
            onClick={() => {
              setMembersList((prevMembers) =>
                prevMembers.map((item) =>
                  item.value === memberDetails.id
                    ? { ...item, wants_calling: wantsCalling, wants_ministers: wantsMinisters, wants_to_minister: wantsToMinister, is_inactive: isInactive, do_not_contact: doNotContact }
                    : item
                )
              );
              setShowDetails(false)
            }}
          >
            <i className="bx bx-x" style={{ fontSize: "22px" }}></i>
          </div>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "15px",
            }}
          >
            {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
              <button
                className="photo-toggle bx bx-chevron-right"
                onClick={togglePhoto}
                style={{
                  position: "absolute",
                  right: "-25px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            )}
            <div
              style={{
                height: "380px",
                width: "380px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo" /> : <NoProfilePicture />}
            </div>
            {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
              <button
                className="photo-toggle bx bx-chevron-left"
                onClick={togglePhoto}
                style={{
                  position: "absolute",
                  left: "-25px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  border: "none",
                  background: "none",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            )}
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px',
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px",
          }}>
            <i className={member.gender === 'F' ? "bx bx-female" : "bx bx-male"} style={{ fontSize: "28px" }}></i>
            <div style={{ fontSize: "18px" }}>
              <strong>{name}{memberDetails.age ? ` (${memberDetails.age})`: ""}</strong>
            </div>
          </div>
          {sessionInfo.canAccessNotes && memberDetails.age >= 12 && (
            <div style={{ display: "flex", marginLeft: "10px" }}>
              <div onClick={() => setActiveTab("contact")} className={`menu-item ${activeTab === "contact" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Contacto" : "Contact"}
              </div>
              <div
                onClick={() => setActiveTab("notes")}
                className={`menu-item ${activeTab === "notes" ? "selected" : ""}`}
              >
                {unitDetails.language === "es" ? "Notas" : "Notes"}
              </div>
              <div onClick={() => setActiveTab("settings")} className={`menu-item ${activeTab === "settings" ? "selected" : ""}`}>
                {unitDetails.language === "es" ? "Configuración" : "Settings"}
              </div>
            </div>
          )}
          <hr style={{ width: "100%", border: "0.25px solid #e0e0e0", padding: "0px" }} />
          {activeTab === "contact" && (
            <div style={{ margin: "10px" }}>
              {memberDetails.birthdate && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <i className="bx bxs-cake" style={{ fontSize: "22px" }}></i>
                  <div>
                    {unitDetails.language === "es" ? formatSpanishBirthdate(new Date(memberDetails.birthdate)) : formatEnglishBirthdate(new Date(memberDetails.birthdate))}
                  </div>
                </div>
              )}
              {memberDetails.phone_number && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <i className="bx bxs-phone" style={{ fontSize: "22px" }}></i>
                  <div
                    onClick={() => copyToClipboard(member.phone_number, "phone_number")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isPhoneNumberHovered && copiedField !== "phone_number" ? "#f0f0f0" : "transparent", 
                      fontStyle: copiedField === "phone_number" ? "italic" : "normal"
                    }}
                    onMouseEnter={() => setIsPhoneNumberHovered(true)} 
                    onMouseLeave={() => setIsPhoneNumberHovered(false)}
                  >
                    {copiedField === "phone_number" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : member.phone_number}
                  </div>
                </div>
              )}
              {memberDetails.email && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <i className="bx bxs-envelope" style={{ fontSize: "22px" }}></i>
                  <div
                    onClick={() => copyToClipboard(member.email, "email")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isEmailHovered && copiedField !== "email" ? "#f0f0f0" : "transparent", 
                      fontStyle: copiedField === "email" ? "italic" : "normal"
                    }}
                    onMouseEnter={() => setIsEmailHovered(true)} 
                    onMouseLeave={() => setIsEmailHovered(false)}
                  >
                    {copiedField === "email" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : member.email}
                  </div>
                </div>
              )}
              {memberDetails.address_line_1 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "8px",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <i className="bx bxs-map" style={{ fontSize: "22px" }}></i>
                  <div
                    onClick={() => copyToClipboard([memberDetails.address_line_1, memberDetails.address_line_2, memberDetails.address_line_3].filter(Boolean).join(", "), "address")}
                    style={{
                      cursor: "pointer",
                      backgroundColor: isAddressHovered && copiedField !== "address" ? "#f0f0f0" : "transparent", 
                      fontStyle: copiedField === "address" ? "italic" : "normal"
                    }}
                    onMouseEnter={() => setIsAddressHovered(true)} 
                    onMouseLeave={() => setIsAddressHovered(false)}
                  >
                    {copiedField === "address" ? (unitDetails.language === "es" ? "¡Copiado!" : "Copied!") : [memberDetails.address_line_1, memberDetails.address_line_2, memberDetails.address_line_3].filter(Boolean).join(", ")}
                  </div>
                </div>
              )}
            </div>
          )}
          {sessionInfo.canAccessNotes && activeTab === "notes" && (
            <>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}>
                <textarea
                  value={noteText}
                  onChange={(e) => setNoteText(e.target.value)}
                  placeholder={unitDetails.language === "es" ? "Escribir una nota..." : "Write a note..."}
                  rows="2"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    width: "90%",
                    fontFamily: "inherit",
                    fontSize: "16px",
                    resize: "none",
                    marginBottom: "2px"
                  }}
                />
                <button onClick={handleSave} className="cta" disabled={noteText.length === 0}>
                  {unitDetails.language === "es" ? "Guardar" : "Save"}
                </button>
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}>
                {notes.map((note, index) => (
                  <div key={index} className="note-item">
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <div>{note.note}</div>
                      <small style={{ fontStyle: 'italic' }}>
                        {note.author} &#8212; {new Date(note.created_date).toLocaleString(undefined, options)}
                      </small>
                    </div>
                    {note.is_author && (
                      <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px' }}>
                        <i 
                          className="bx bx-trash delete-icon" 
                          onClick={(e) => deleteNote(note.id)}
                        ></i>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          {sessionInfo.canAccessNotes && activeTab === "settings" && (
            <div style={{ margin: "10px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: doNotContact || isInactive ? 0.65 : 1,
                  color: doNotContact || isInactive ? "#808080" : "inherit",
                }}
              >
                <i
                  className={wantsCalling ? "bx bxs-check-square" : "bx bx-square"}
                  style={{ 
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: doNotContact || isInactive ? "default" : "pointer",
                    pointerEvents: doNotContact || isInactive ? "none" : "auto",
                  }}
                  onClick={() => updateWantsCalling(!wantsCalling)}
                ></i>
                {unitDetails.language === "es" ? "Quiere un llamamiento" : "Wants a calling"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: WantsCallingTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: doNotContact || isInactive ? 0.65 : 1,
                  color: doNotContact || isInactive ? "#808080" : "inherit",
                }}
              >
                <i
                  className={wantsToMinister ? "bx bxs-check-square" : "bx bx-square"}
                  style={{ 
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: doNotContact || isInactive ? "default" : "pointer",
                    pointerEvents: doNotContact || isInactive ? "none" : "auto",
                  }}
                  onClick={() => updateWantsToMinister(!wantsToMinister)}
                ></i>
                {unitDetails.language === "es" ? "Quiere ministrar" : "Wants to minister"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: WantsToMinisterTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: doNotContact ? 0.65 : 1,
                  color: doNotContact ? "#808080" : "inherit",
                }}
              >
                <i
                  className={wantsMinisters ? "bx bxs-check-square" : "bx bx-square"}
                  style={{ 
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: doNotContact ? "default" : "pointer",
                    pointerEvents: doNotContact ? "none" : "auto",
                  }}
                  onClick={() => updateWantsMinisters(!wantsMinisters)}
                ></i>
                {unitDetails.language === "es" ? "Quiere ministros" : "Wants ministers"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: WantsMinistersTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "4px",
                  opacity: doNotContact ? 0.65 : 1,
                  color: doNotContact ? "#808080" : "inherit",
                }}
              >
                <i
                  className={isInactive ? "bx bxs-check-square" : "bx bx-square"}
                  style={{
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: doNotContact ? "default" : "pointer",
                    pointerEvents: doNotContact ? "none" : "auto",
                  }}
                  onClick={() => {
                    updateIsInactive(!isInactive);
                    if (!isInactive) {
                      updateWantsCalling(false);
                      updateWantsToMinister(false);
                    }
                  }}
                ></i>
                {unitDetails.language === "es" ? "Inactivo" : "Inactive"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: IsInactiveTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
              <div style={{ display: "flex", alignItems: "center", padding: "4px", gap: "5px" }}>
                <i
                  className={doNotContact ? "bx bxs-check-square" : "bx bx-square"}
                  style={{
                    fontSize: "22px",
                    borderRadius: '2px',
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    updateDoNotContact(!doNotContact);
                    if (!doNotContact) {
                      updateWantsCalling(false);
                      updateWantsToMinister(false);
                      updateWantsMinisters(false);
                      updateIsInactive(true);
                    }
                  }}
                ></i>
                {unitDetails.language === "es" ? "No contactar" : "Do not contact"}
                <Tippy content={<div dangerouslySetInnerHTML={{ __html: DoNotContactTooltip }} />} theme="lcrplus" zIndex={1000000}>
                  <i className="bx bx-info-circle" style={{ fontSize: "14px" }}></i>
                </Tippy>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
