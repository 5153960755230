import React from "react";

export const AddMinisteringCompanionship = ({
  onAdd,
  districtId,
}) => {
  return (
    <div
      className="sub-organization-section" 
      style={{ cursor: "pointer" }}
      onClick={() => onAdd(districtId)}>
        <div
          className="bx bx-expand-vertical"
          style={{ fontSize: "24px", color: "black" }}
        ></div>
    </div>
  );
};
