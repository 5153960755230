import { formatName } from "./utils";
import React, { useState } from "react";
import { MemberDetails } from "./MemberDetails";
import { NoProfilePicture } from "./NoProfilePicture";

export const ProspectiveElder = ({ member, unitDetails, sessionInfo, setMembersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  const name = formatName(member.member_name)
  let borderColor = "#b0b0b0";

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `5px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "240px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {memberPhotoSrc && householdPhotoSrc && !showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-right"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                right: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
          <div
            style={{
              height: "210px",
              width: "210px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo" /> : <NoProfilePicture />}
          </div>
          {memberPhotoSrc && householdPhotoSrc && showHouseholdPhoto && (
            <button
              className="photo-toggle bx bx-chevron-left"
              onClick={togglePhoto}
              style={{
                position: "absolute",
                left: "-15px",
                top: "50%",
                transform: "translateY(-50%)",
                border: "none",
                background: "none",
                fontSize: "24px",
                cursor: "pointer",
              }}
            />
          )}
        </div>
        <div
          style={{
            fontSize: "18px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            cursor: "pointer",
          }}
          onClick={() => setShowDetails(true)}
        >
          <strong>{name}</strong>
        </div>
        <div
          style={{
            fontSize: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: "240px",
            fontStyle: "italic",
            marginBottom: "8px",
          }}
        >
          {member.position}
        </div>
        <div style={{ fontSize: "15px", display: "flex", gap: "8px" }}>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Sacerdocio:" : "Priesthood:"}</strong>
            </div>
            <div>{member.priesthood_office}</div>
          </div>
          <div
            style={{
              borderRight: "1px solid #261232",
              height: "80%",
              margin: "0 10px",
            }}
          ></div>
          <div>
            <div>
              <strong>{unitDetails.language === "es" ? "Edad:" : "Age:"}</strong>
            </div>
            <div>{member.age}</div>
          </div>
        </div>
        {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} setMembersList={setMembersList} />}
      </div>
    </>
  );
};