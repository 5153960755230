import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@radix-ui/react-dropdown-menu";
import { MemberDetails } from "../MemberDetails";
import { NoProfilePicture } from "../NoProfilePicture";

export const MinisteringCard = ({ member, updateMinisteringAssignment, updateMinisteringCompanion, deleteMinisteringAssignment, deleteMinisteringCompanion, sessionInfo, unitDetails, setMembersList }) => {
  const [showHouseholdPhoto, setShowHouseholdPhoto] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [isPendingUnassignment, setIsPendingUnassignment] = useState(member.is_pending_unassignment);

  // Function to toggle between member and household photo
  const togglePhoto = () => {
    setShowHouseholdPhoto(!showHouseholdPhoto);
  };

  // Prepare photo sources
  let memberPhotoSrc = member.member_photo;
  let householdPhotoSrc = member.household_photo;

  // Determine which photo to display
  let currentPhotoSrc;
  if (memberPhotoSrc && householdPhotoSrc) {
    currentPhotoSrc = showHouseholdPhoto ? householdPhotoSrc : memberPhotoSrc;
  } else if (memberPhotoSrc) {
    currentPhotoSrc = memberPhotoSrc;
  } else if (householdPhotoSrc) {
    currentPhotoSrc = householdPhotoSrc;
  }

  const name = member.name.split(",").map((part) => part.trim()).reverse().join(" ");
  let borderColor = "#b0b0b0";
  if (["BISHOPRIC", "PRESIDENCY"].includes(sessionInfo.accessLevel) && sessionInfo.canAccessNotes) {
    if (member.is_auto_generated) {
      borderColor = "#2684FC"
    } else if (isPendingUnassignment) {
      borderColor = "#ea4335"
    } else if (member.is_manually_created) {
      borderColor = "#d988bb"
    }
  }

  const handleUpdateIsPendingUnassignment = () => {
    setIsPendingUnassignment(!isPendingUnassignment);
    if (member.ministering_assignment_id) {
      updateMinisteringAssignment(member.ministering_assignment_id, !isPendingUnassignment);
    } else if (member.ministering_companion_id) {
      updateMinisteringCompanion(member.ministering_companion_id, !isPendingUnassignment);
    }
  };

  const handleDelete = () => {
    if (member.ministering_assignment_id) {
      deleteMinisteringAssignment(member.ministering_assignment_id);
    } else if (member.ministering_companion_id) {
      deleteMinisteringCompanion(member.ministering_companion_id);
    }
  }

  return (
    <>
      <div
        className="member-card"
        style={{
          border: `3px solid ${borderColor}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          height: "150px",
        }}
      >
        {sessionInfo.accessLevel === "PRESIDENCY" && sessionInfo.canAccessNotes &&
          <DropdownMenu>
            <DropdownMenuTrigger 
              style={{ cursor: "pointer", position: "absolute", top: "0px", right: "-5px", zIndex: 1000 }} 
              asChild
            >
              <i className="bx bx-dots-vertical-rounded three-dots" style={{ fontSize: "24px" }}></i>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="dropdown-content"
              align="end"
              side="bottom"      
              style={{zIndex: 1000}}
            >
              <DropdownMenuRadioGroup>
                {!isPendingUnassignment && !member.is_auto_generated && !member.is_manually_created && (
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={handleUpdateIsPendingUnassignment}
                    style={{ fontSize: "18px" }}
                  >
                    <i className="bx bx-user-minus" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    Unassign
                  </DropdownMenuRadioItem>
                )}
                {isPendingUnassignment && !member.is_auto_generated && !member.is_manually_created && (
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={handleUpdateIsPendingUnassignment}
                    style={{ fontSize: "18px" }}
                  >
                    <i className="bx bx-undo" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    Clear unassigned status
                  </DropdownMenuRadioItem>
                )}
                {(member.is_auto_generated || member.is_manually_created) && (
                  <DropdownMenuRadioItem
                    className="dropdown-item"
                    onClick={handleDelete}
                    style={{ fontSize: "18px" }}
                  >
                    <i className="bx bx-trash" style={{ marginRight: "5px", fontSize: "16px" }}></i>
                    Delete
                  </DropdownMenuRadioItem>
                )}
              </DropdownMenuRadioGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        }
        <div
          style={{
            height: "125px",
            width: "125px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          {currentPhotoSrc ? <img src={currentPhotoSrc} alt={name} className="member-photo" /> : <NoProfilePicture />}
        </div>
        <div className="name" onClick={() => setShowDetails(true)}>
          {name}
        </div>
      </div>
      {showDetails && <MemberDetails member={member} setShowDetails={setShowDetails} sessionInfo={sessionInfo} unitDetails={unitDetails} setMembersList={setMembersList} />}
    </>
  );
};