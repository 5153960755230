export const MinisteringLegend = ({ unitDetails }) => {
  return (
    <div className="legend">
      <div className="legend-controls">
        <div></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#d988bb",
              backgroundColor: "#d988bb",
            }}
          />
          <strong>{unitDetails.language === "es" ? "Creado manualmente" : "Manually created"}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#2684FC",
              backgroundColor: "#2684FC",
            }}
          />
          <strong>{unitDetails.language === "es" ? "Autogenerado" : "Auto-generated"}</strong>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="bx bx-square-rounded"
            style={{
              marginRight: "4px",
              fontSize: "18px",
              borderRadius: "4px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#ea4335",
              backgroundColor: "#ea4335",
            }}
          />
          <strong>{unitDetails.language === "es" ? "Desasignación pendiente" : "Pending unassignment"}</strong>
        </div>
        <div></div>
      </div>
    </div>
  );
};